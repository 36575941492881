import { SequenceStepChunkDefinition } from '@/sequences/SequenceStepChunk/base_classes'
import { generateUUID, superMegaCoolErrorStyle } from '@/utils/misc'
import { computed } from '@vue/composition-api'
import { sequencesObj } from '@/store/modules/sequences'
import { isFunction } from 'lodash'

import {store} from '@/store'

const executeExternalAction = externalAction => {
    if (isFunction(externalAction))
        return externalAction()
    return externalAction
}


export default {
    name: 'SequenceChunkWrapper',
    functional: true,
    props: {
        sequenceId: {
            type: String,
            required: true
        },
        stepId: {
            type: String,
            required: true
        },
        chunkId: {
            type: String,
            default: SequenceStepChunkDefinition.DEFAULT_ID
        },
        anchorChildIndex: {
            type: [Number, String],
            default: 0
        }
    },
    render(createElement, ctx) {
        const throwError = message => {
            console.error(`%c${message}`, superMegaCoolErrorStyle)
            throw new Error(`\n\nsequenceId: ${ctx.props.sequenceId}\nstepId: ${ctx.props.stepId}\nchunkId: ${ctx.props.chunkId}\n\n${message}\n\n`)
        }

        const isActive = computed(
            () => ctx.props.sequenceId === ctx.parent.$store.getters['sequences_store/getActiveSequenceId']
                && ctx.props.stepId === ctx.parent.$store.getters['sequences_store/getActiveStepId']
                && ctx.parent.$store.getters['sequences_store/getActiveChunksIds'].includes(ctx.props.chunkId)
        )

        const allChildren = ctx.scopedSlots.default({
            processChunkAction: async (externalAction, {
                nestedWrapper = false,
                mustReturnTrue = false,
                enabledIfEducationIsInactive = false,
            } = {}) => {
                if (store.getters['mainScreen/getEducationIsChosen']) {
                    if (isActive.value) {
                        await ctx.parent.$store.dispatch('sequences_store/takeSnapshot', {...ctx.props})
                        const result = executeExternalAction(externalAction)

                        if ((mustReturnTrue && result) || !mustReturnTrue)
                            ctx.parent.$store.dispatch('sequences_store/processChunkAction', {...ctx.props})

                        return result
                    } else if (nestedWrapper) {
                        return executeExternalAction(externalAction)
                    }
                }
                else if (enabledIfEducationIsInactive) {
                    executeExternalAction(externalAction);
                }

            },
            isActive: isActive.value
        })

        const anchorChildIndex = parseInt(ctx.props.anchorChildIndex)

        if (!(anchorChildIndex in allChildren))
            throwError('Can\'t find child with provided anchor-child-index')

        let anchorChild = allChildren[anchorChildIndex]

        if (!anchorChild?.tag)
            throwError('Anchor child must have tag')

        const anchorDataAttr = `data-sequence-chunk-${generateUUID()}`
        const anchorSelector = `[${anchorDataAttr}]`

        anchorChild.data = {
            ...(anchorChild?.data || {}),
            attrs: {
                ...(anchorChild?.data?.attrs || {}),
                [anchorDataAttr]: '',
                ...((ctx.props.stepId === ctx.parent.$store.getters['sequences_store/getActiveStep']?.definition?.id && ctx.parent.$store.getters['sequences_store/getActiveChunks']?.some(({definition: {id}}) => id === ctx.props.chunkId)) && ({
                    'data-enable-pointer-events': true
                }))
            },

        }

        const sequenceDefinition = sequencesObj?.[ctx.props.sequenceId]
        if (!sequenceDefinition) throwError('Unknown sequence')

        const stepDefinition = sequenceDefinition?.stepById?.[ctx.props.stepId]
        if (!stepDefinition) throwError('Unknown step')

        const chunkDefinition = stepDefinition?.chunkById?.[ctx.props.chunkId]
        if (!chunkDefinition) throwError('Unknown chunk')

        return [
            ...allChildren,
            ...chunkDefinition.components
                .map(componentImportStatement => createElement(componentImportStatement, {
                    props: {
                        anchorSelector,
                        ...ctx.props,
                        chunkDefinition
                    }
                }))
        ]
    }
}